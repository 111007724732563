<template>
    <v-container v-if="items.length > 0">
        <v-data-table @click:row="navigateRow" :search="search" :headers="headers" :custom-filter="filterGrid"
            :items="items" hide-default-footer>

            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-toolbar-title>Lijsten</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" label="Zoek in grid" class="mx-4"></v-text-field>
                </v-toolbar>
            </template>
            <template v-slot:[`item.navigeer`]="{ }">
                <v-icon medium>mdi-chevron-double-right</v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>

import client from "api-client";

export default {
    name: "AdminAudits",
    data: () => ({
        search: "",
        items: [],
        dialog: false,
        valid: false,
        headers: [
            { text: "Naam", value: "naam", sortable: true },
            { text: "", value: "navigeer", align: 'end' },
        ],
        rules: {
            required: (value) => !!value || "Verplicht.",
        },
        itemModel: {},
    }),
    methods: {
        filterGrid(value, search) {
            return (
                value != null &&
                search != null &&
                typeof value === "string" &&
                value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
            );
        },
        getAudits() {
        
            client.fetchAudits(this.$route.params.instantieId).then((data) => {
                this.items = data

                if (this.items.length == 1) {
                    this.navigateRow({ id: this.items[0].id })
                }
            });
        },
        navigateRow(row) {
            this.$router.push({ name: 'AuditDashboard', params: { auditId: row.id } })
        },
    },
    created() {
        this.getAudits()
    }
}
</script>


